<template>
	<div class="tree-expand">
		<span class="tree-label" v-show="DATA.isEdit">
			<el-input class="edit" size="mini" autofocus
			v-model="DATA.simpleName"
			:ref="'treeInput'+DATA.id"
			@click.stop.native="nodeEditFocus"
			@blur.stop="nodeEditPass(STORE,DATA,NODE)"
			@keyup.enter.stop.native="nodeEditPass(STORE,DATA,NODE)"></el-input>
		</span>
		<span v-show="!DATA.isEdit"
		:class="[DATA.id > maxexpandId ? ' tree-label' : 'tree-label']">
			<span>{{DATA.simpleName}}</span>
		</span>
		
		<div class="tree-btn" v-show="!DATA.isEdit">
			<div @click.stop="nodeAdd(STORE,DATA,NODE)" v-if="DATA.id == '6ff9ee297ae8402d9109650aeb26281d'&&!DATA.isEdit" class="addButton">
				<img class="addImg" src="~@/assets/img/addBtn.png" alt="">
				新增机构
				</div>
			<!-- <i v-if="isAuth('org-users-dept-add')" class="el-icon-plus" @click.stop="nodeAdd(STORE,DATA,NODE)"></i>
			<i v-if="isAuth('org-users-dept-edit')" class="el-icon-edit" @click.stop="nodeEdit(STORE,DATA,NODE)"></i>
			<i v-if="isAuth('org-users-dept-delete') && NODE.level !== 1" class="el-icon-delete" @click.stop="nodeDel(STORE,DATA,NODE)"></i> -->

	<!-- 		<i  v-if="NODE.level !== 1" class="el-icon-edit" @click.stop="nodeEdit(STORE,DATA,NODE)"></i>
			<i v-if="NODE.level !== 1"  class="el-icon-delete" @click.stop="nodeDel(STORE,DATA,NODE)"></i> -->
			<img src="~@/assets/img/edit-btn.png" style="width: 14px;height: 14px;position: absolute;right: 25px;margin-top:-5px;"  v-if="NODE.level !== 1" @click.stop="nodeEdit(STORE,DATA,NODE)" alt="">
			<img src="~@/assets/img/delete-btn.png" style="width: 14px;height: 14px;position: absolute;right: 0px;margin-top:-5px;"  v-if="NODE.level !== 1" @click.stop="nodeDel(STORE,DATA,NODE)" alt="">
			
		</div>
	</div>
</template>

<script>
	export default{
		name: 'treeExpand',
		props: ['NODE', 'DATA', 'STORE', 'maxexpandId'],
		methods: {
			nodeAdd(s,d,n){//新增
				this.$emit('nodeAdd',s,d,n)
			},
			nodeEdit(s,d,n){//编辑
				d.isEdit = true;
				this.$nextTick(() => {
					this.$refs['treeInput'+d.id].$refs.input.focus()
				})

			},
			nodeDel(s,d,n){//删除
				this.$emit('nodeDel',s,d,n)
			},
			nodeEditPass(s,d,n){//编辑完成
				d.isEdit = false;
				this.$emit('nodeEdit',s,d,n)
			},
			nodeEditFocus(){
				//阻止点击节点的事件冒泡
			},
		}
	}
</script>

<style lang="scss">
	.addButton{
		cursor: pointer;
		margin-left: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		color: #0476F5;
		width: 80px;
		height: 24px;
		line-height: 24px;
		border-radius: 2px;
		border: 1px solid #0476F5;
		.addImg{
			width:14px;
			height: 14px;
			margin-right: 3px;
		}
	}
	.tree-expand{
		overflow:hidden;
		display: flex;
		align-items: center;
	}
	.tree-expand .tree-label.tree-new{
		font-weight:600;
	}
	.tree-expand .tree-label{
		font-size:0.9em;
	}
	.tree-expand .tree-label .edit{
		width:80%;
	}
	.tree-expand .tree-btn{
		display:none;
		float:right;
		margin-right:20px;
	}
	.tree-expand .tree-btn i{
		color:#8492a6;
		font-size:0.9em;
		margin-right:3px;
	}
</style>
