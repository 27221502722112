<template>
	<div class="orgexpand" >
		<div>
			<el-input style="width: 318px;margin-top: 24px;margin-left: 8px;" v-model="name" placeholder="输入机构名称查询">
				<el-button slot="append" icon="el-icon-search" @click="getDeptList()"></el-button>
			</el-input>
			<el-tree ref="expandMenuList" class="expand-tree" v-if="isLoadingTree" :data="setTree" node-key="id"
			 :props="defaultProps" :expand-on-click-node="false" :render-content="renderContent"
			 :default-expand-all="isLoadingTree" :default-expanded-keys="defaultExpandKeys" @node-click="handleNodeClick"></el-tree>
		</div>
	</div>
</template>
<!-- VUE饿了么树形控件添加增删改功能按钮 -->
<script>
	import Qs from 'qs'
	import TreeRender from './tree_render'
	import api from '@/resource/api'
	import {
		treeDataTranslate
	} from '@/utils'
	export default {
		name: 'tree',
		data() {
			return {
				name:'',
				//         maxexpandId: api.maxexpandId,//新增节点开始id
				//         non_maxexpandId: api.maxexpandId,//新增节点开始id(不更改)
				maxexpandId: '', //新增节点开始id
				non_maxexpandId: '', //新增节点开始id(不更改)
				num: '', //排序
				isLoadingTree: true, //是否加载节点树
				setTree: [], //节点树数据
				clientHeight: `${document.documentElement.clientHeight}` - 50 - 30 - 2 - 80,
				defaultProps: {
					children: 'children',
					label: 'simpleName'
				},
				defaultExpandKeys: [], //默认展开节点列表
			}
		},
		mounted() {
			this.initExpand()
			this.getDeptList()
		},
		activated() {
			
		},
		methods: {
			initExpand() {
				this.setTree.map((a) => {
					this.defaultExpandKeys.push(a.id)
				});
				this.isLoadingTree = true;
			},
			// 获取部门列表
			getDeptList(data) {
				let user = JSON.parse(sessionStorage.getItem("userInfo"))
// 				let obj = {
// 					deptId: user.deptId,
// 					'userId':this.$cookie.get('userId'),
// 				}
				this.$httpAes({
					url: this.$httpAes.adornUrl('dept/list'),
					method: 'post',
				data:{
					stringParam1:this.name,
					// stringParam2:'',
					 stringParam3:JSON.parse(localStorage.userInfo).deptId,
					// stringParam4:'',
					// intParam1:2,
					//intParam1:1,
					pageNo:1,
					pageSize:99999
				},
					contentType:'json',
					headers: {
						'Content-Type': 'application/json',
					   				
					},
					// data: Qs.stringify(obj)
				}).then(({
					data
				}) => {
					if(user.deptId!=null){
						let obj = {
							id:user.deptId,
							fullName:user.deptName,
							simpleName:user.deptName,
							num:'1',
							pids:''
						}
						// data.data.unshift(obj)
					}
					for (var i = 0; i < data.data.length; i++) {
						data.data[i].isEdit = false;
						data.data[i].children = [];
					}

					let tree = treeDataTranslate(data.data)
					console.log(tree)
					this.setTree = tree
					this.maxexpandId = data.data[data.data.length - 1].id
					this.non_maxexpandId = data.data[data.data.length - 1].id
					this.num = data.data[data.data.length - 1].num

				})
			},

			handleNodeClick(d, n, s) { //点击节点
				d.isEdit = false; //放弃编辑状态
				this.$emit("refreshDataList", d)
			},
			renderContent(h, {
				node,
				data,
				store
			}) { //加载节点
				let that = this;
				return h(TreeRender, {
					props: {
						DATA: data,
						NODE: node,
						STORE: store,
						maxexpandId: that.non_maxexpandId
					},
					on: {
						nodeAdd: ((s, d, n) => that.handleAdd(s, d, n)),
						nodeEdit: ((s, d, n) => that.handleEdit(s, d, n)),
						nodeDel: ((s, d, n) => that.handleDelete(s, d, n))
					}
				});
			},

			handleAdd(s, d, n) { //增加节点
				//         if(n.level >=6){
				//           this.$message.error("最多只支持五级！")
				//           return false;
				//         }
				//添加数据
				let pidStr = ''
				if(d.pids==''){
					pidStr=d.id
				}else
				{
					pidStr=d.pids + ',' + d.id
				}
				d.children.push({
					id: '',
					num: ++this.num,
					simpleName: '新增节点',
					pid: d.id,
					pids: pidStr ,
					isEdit: false,
					children: []
				});
				//展开节点
				if (!n.expanded) {
					n.expanded = true;
				}
				let Dept = {
					'userId':this.$cookie.get('userId'),
					'num': this.num,
					'pid': d.id,
					pids: pidStr ,
					"simpleName": "新增节点",
					"fullName": "新增节点",
					"tips": '',
					"version": '',
				}
				this.$httpAes({
					url: this.$httpAes.adornUrl('/dept/add'),
					method: 'post',
					data: Dept,
					contentType:'json',
					headers: {
						'Content-Type': 'application/json',
					   				
					},
				
				}).then(({
					data
				}) => {
					if (data.status ) {
						this.getDeptList()
					}
				})
			},
			handleEdit(s, d, n) { //编辑节点
				let Dept = d
				Dept.fullName = Dept.simpleName
				this.$httpAes({
					url: this.$httpAes.adornUrl('/dept/update'),
					method: 'post',
					data:Dept,
						contentType:'json',
						headers: {
							'Content-Type': 'application/json',
						   				
						},
				
				}).then(({
					data
				}) => {

					if (data.code == 10000) {
						this.getDeptList()
					}
				})
			},
			handleDelete(s, d, n) { //删除节点
			console.log(d)
				let that = this;
				let _menu = d.children;
				let menuId = [d.id];
				let len = _menu.length;
				for (let i = 0; i < len; i++) {
					let item = _menu[i];
					if (item.children && item.children.length != 0) {
						let children = item.children;
						for (let j = 0; j < children.length; j++) {
							_menu[len + j] = children[j];
						}
						len = _menu.length;
					}
					menuId.push(item.id);
				}
				console.log(menuId)
				let ids = {
			
					stringParam1: menuId.join(',')
				}
				console.log(ids)
				this.$httpAes({
					url: this.$httpAes.adornUrl('dept/delete'),
					method: "post",
					data: ids,
						contentType:'json',
						headers: {
							'Content-Type': 'application/json',
						   				
						},
			
				}).then(({
					data
				}) => {
					if (data.status) {
						this.$message.success("删除成功！")
						this.getDeptList()
					}
				})
				//有子级不删除
				//         if(d.children && d.children.length !== 0){
				//           this.$message.error("此节点有子级，不可删除！")
				//           return false;
				//         }else{
				//           //新增节点直接删除，否则要询问是否删除
				//           let delNode = () => {

				//             let list = n.parent.data.children || n.parent.data,//节点同级数据
				//               _index = 99999;//要删除的index
				//             /*if(!n.parent.data.children){//删除顶级节点，无children
				//               list = n.parent.data
				//             }*/
				//             list.map((c,i) => {
				//               if(d.id == c.id){
				//                 _index = i;
				//               }
				//             })
				//             let k = list.splice(_index,1);
				//             //console.log(_index,k)
				//             this.$message.success("删除成功！")
				//           }
				//           let isDel = () => {
				//             that.$confirm("是否删除此节点？","提示",{
				//               confirmButtonText: "确认",
				//               cancelButtonText: "取消",
				//               type: "warning"
				//             }).then(() => {
				//               delNode()
				//             }).catch(() => {
				//               return false;
				//             })
				//           }
				//           //判断是否新增
				//           d.id > this.non_maxexpandId ? delNode() : isDel()
				//           
				//         }
			},
		}

	}
</script>

<style>
	
	.orgexpand {
		width: 329px;
		height: 700px;
		overflow: hidden;
	}

	.expand>div {
		height: 100%;
		/*  padding-top:20px;
  width:50%;
  margin:20px auto; */
		margin-right: 10px;
	/* 	border: 1px solid #cccccc; */
		max-width: 400px;
		overflow-y: auto;
	}

	.expand>div::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
		border-radius: 5px;
	}

	.expand>div::-webkit-scrollbar-thumb {
		background-color: rgba(50, 65, 87, 0.5);
		outline: 1px solid slategrey;
		border-radius: 5px;
	}

	.expand>div::-webkit-scrollbar {
		width: 10px;
	}

	.expand-tree {
		border: none;
		margin-top: 10px;
	}

	.expand-tree .el-tree-node.is-current,
	.expand-tree .el-tree-node:hover {
		overflow: hidden;
	}

	.el-tree-node__content {
		height: 28px;
	}

	.expand-tree .is-current>.el-tree-node__content .tree-btn,
	.expand-tree .el-tree-node__content:hover .tree-btn {
		display: inline-block;
	}

	.expand-tree .is-current>.el-tree-node__content .tree-label {
		font-weight: 600;
		white-space: normal;
	}
</style>
