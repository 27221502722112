<template>
	<div class="all-rule-list">
		
	<el-container style="padding: 1px;">
		<el-aside style="border: 1px solid #eeeeee;"  width="360px">
			<OrgTree @refreshDataList="getDataList"></OrgTree>
		</el-aside>
		<el-main>
			<div class="mod-user">
				<el-form style="display: flex;justify-content: space-between;" :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
					<el-form-item>
							<el-button type="primary"  @click="addAccount()">添加账号</el-button>
						<el-button type="primary"  @click="addOrUpdateHandle()">编辑</el-button>
						<el-button type="primary" @click="reSetPwd()">重置密码</el-button>
						<el-button @click="deleteHandle()"  :disabled="dataListSelections.length <= 0"
						 type="danger">删除</el-button>
					</el-form-item>
					<el-form-item style="margin-right: 0px;">
						<el-input v-model="searchContent" style="width: 400px;" class="input-with-select" placeholder="输入关键词查询">
							<el-select v-model="select" slot="prepend" placeholder="请选择">
								<el-option label="姓名" value="1"></el-option>
								<el-option label="手机号" value="2"></el-option>
							</el-select>
							<el-button slot="append" @click="getDataList()" icon="el-icon-search"></el-button>
						</el-input>
					</el-form-item>
				</el-form>
				<el-table :data="dataList"  v-loading="dataListLoading" @selection-change="selectionChangeHandle" style="width: 100%;">
					<!-- 	<el-table-column v-if="isAuth('org-users-account-edit')&&isAuth('org-users-account-delete')" type="selection"
					 header-align="center" align="center" width="50">
					</el-table-column>

					<el-table-column prop="name" header-align="center" align="center" width="130" label="姓名">
						<template slot-scope="scope">
							<el-button :disabled="!isAuth('org-users-account-info')" style="color: #17b3a3;" type="text" size="small">{{ scope.row.name }}</el-button>
						</template>
					</el-table-column> -->
					<el-table-column type="selection" :selectable="checkSelectable" header-align="center" align="center" width="50">
					</el-table-column>

					<el-table-column  header-align="center" align="center" width="130" label="姓名">
						<template slot-scope="scope">
							<div style="padding: 11px;">{{scope.row.user.name}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="user.phone" header-align="center" align="center" width="130" label="手机号">
					</el-table-column>
				
					<el-table-column prop="user.deptName" header-align="center" align="center" label="所属机构">
					</el-table-column>
							<el-table-column prop="roleName" header-align="center" align="center" label="成员类型">
					</el-table-column>
					<el-table-column prop="user.createTime" header-align="center" align="center" width="180" label="创建时间">
					</el-table-column>
				</el-table>
				<el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
				 :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
				</el-pagination>
				<!-- 弹窗, 新增 / 修改 -->
				<AddAccount v-if="addVisible" ref="addAccount" @refreshDataList="getDeptList"></AddAccount>
				<EidtAccount v-if="addOrUpdateVisible&&dataListSelections.length!=0" :myData='dataListSelections[0]' ref="addOrUpdate"
				 @refreshDataList="getDataList"></EidtAccount>
			</div>
		</el-main>
	</el-container>
	</div>
</template>
<style scoped>
	.el-select {
		width: 100px;
	}

	.input-with-select .el-input-group__prepend {
		background-color: #fff;
	}
</style>
<script>
	import Qs from 'qs'
	import OrgTree from './tree/tree'
	import AddAccount from './add-account'
	import EidtAccount from './edit-account'
	export default {
		data() {
			return {
				dataForm: {
					userName: ''
				},
				select: '1',
				searchContent: '',
				dataList: [],
				pageIndex: 1,
				pageSize: 10,
				totalPage: 0,
				chooseDept:'',
				dataListLoading: false,
				dataListSelections: [],
				addOrUpdateVisible: false,
				addVisible: false,
			}
		},
		components: {
			OrgTree,
			AddAccount,
			EidtAccount
		},
		created() {
			this.getRoleList()
		},
		computed: {
			deptList: {
				get() {
					return this.$store.state.common.deptList
				},
				set(val) {
					this.$store.commit('common/updateDeptList', val)
				}
			},
			roleList: {
				get() {
					return this.$store.state.common.roleList
				},
				set(val) {
					this.$store.commit('common/updateRoleList', val)
				}
			},
		},
		methods: {
			updateData(data) { //触发子组件城市选择-选择城市的事件
			},
			// 获取角色列表
			getRoleList() {
let deptId = JSON.parse(sessionStorage.getItem("userInfo")).deptId
				let obj = {
					'userId': this.$cookie.get('userId'),
					pageNo: 1,
					pageSize: 99,
					deptId: deptId
				}

				this.$httpAes({
					url: this.$httpAes.adornUrl('role/page'),
					method: 'post',
					data: obj,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',
					
					},
				}).then(({
					data
				}) => {
					this.roleList = data.data
					this.getDeptList()
				})
			},
			// 获取部门列表
			getDeptList(data) {
				let user = JSON.parse(sessionStorage.getItem("userInfo"))
				// 				let obj = {
				// 					'num'	: '1',
				// 'pid'	:'',
				// 'pids':'',	
				// 'simpleName'	:'江苏省教师培训中心',
				// 'fullName':'江苏省教师培训中心'
				// 				}
				// this.$httpAes({
				// 					url: this.$httpAes.adornUrl('dept/add'),
				// 					method: 'post',
				// 					headers: {
				// 						'Content-Type': 'application/x-www-form-urlencoded',
				// 			
				// 					},
				// 						data: Qs.stringify(obj)
				// 				}).then(({
				// 					data
				// 				}) => {
				// 					console.log(data.data)
				// 				})
				this.$httpAes({
					url: this.$httpAes.adornUrl('dept/list'),
					method: 'post',
					data:{
						stringParam1:'',
						stringParam2:'',
							 stringParam3:JSON.parse(localStorage.userInfo).deptId,
						stringParam4:'',
						//intParam1:2,
						pageNo:1,
						pageSize:99999
					},
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',
					
					},
				}).then(({
					data
				}) => {
					if(user.deptId!=null){
						let obj = {
							id:user.deptId,
							fullName:user.deptName,
							simpleName:user.deptName,
							num:'1'
						}
						data.data.unshift(obj)
					}
					this.deptList = data.data
					this.getDataList()
				})
			},
			// 获取数据列表
			getDataList(data) {
				let user = JSON.parse(sessionStorage.getItem("userInfo"))
				let name = ""
				let mobile = ""
				if (this.select == "1") {
					name = this.searchContent
				} else if (this.select == "2") {
					mobile = this.searchContent
				}
				let deptId = ""
				if (data) {
					deptId = data.id
					this.chooseDept = data.id
				} else if(user.deptId!=null) {
					
					deptId = user.deptId
				}

				let obj = {
				
					stringParam5: this.pageIndex,
					stringParam4: deptId,
					stringParam6: this.pageSize,
					stringParam1: name,
					stringParam2: mobile
				}
				this.dataListLoading = true
				this.$httpAes({
					url: this.$httpAes.adornUrl('user/backUserList?pageSize=' + this.pageSize + '&pageNo=' + this.pageIndex +
						'&deptId=' + deptId+'&keyId=6ff9ee297ae8402d9109'  ),
				
					method: 'post',
					data:obj,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',
					
					},
				}).then(({
					data
				}) => {
					if (data.status) {
						var dataList = data.data
						var roleList = this.roleList

						var deptList2 = this.deptList

						for (var i = 0; i < dataList.length; i++) {
							var roleObj = roleList.find(function(x) {
								return x.id == dataList[i].roleId
							})
// 							if (roleObj != undefined) {
// 								dataList[i].roleName = roleObj.name
// 							} else {
// 								dataList[i].roleName = '超级管理员'
// 							}
							var deptObj = deptList2.find(function(x) {
								return x.id == dataList[i].user.deptId
							})
							if (deptObj != undefined) {
								dataList[i].user.deptName = deptObj.fullName
							} else {
								dataList[i].user.deptName = ''
							}

						}
						this.dataList = data.data;
						this.totalPage = data.totalCount
						// this.totalPage = data.page.totalCount
					} else {
						this.dataList = []
						this.totalPage = 0
					}
					this.dataListLoading = false
				})
			},
			// 获取数据列表
			getDataList2(data) {
				let user = JSON.parse(sessionStorage.getItem("userInfo"))
				let name = ""
				let mobile = ""
				if (this.select == "1") {
					name = this.searchContent
				} else if (this.select == "2") {
					mobile = this.searchContent
				}
				let deptId = ""
				if (data) {
					deptId = data.id
				} else {
					
					deptId = user.deptId
				}
			
				let obj = {
					'userId': this.$cookie.get('userId'),
					pageNo: this.pageIndex,
					deptId: this.chooseDept,
					pageSize: this.pageSize,
					name: name,
					mobile: mobile
				}
				this.dataListLoading = true
				this.$httpAes({
					url: this.$httpAes.adornUrl('user/backUserList?pageSize=' + this.pageSize + '&pageNo=' + this.pageIndex +
						'&pid=' + user.deptId+'&name=' + name+'&phone=' +mobile+'&deptId=' +this.chooseDept+'&keyId=6ff9ee297ae8402d9109'  ),
					method: 'post',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						
						'token': this.$cookie.get('token'),
					},
				}).then(({
					data
				}) => {
					if (data.status) {
						var dataList = data.data
						var roleList = this.roleList
			
						var deptList2 = this.deptList
			
						for (var i = 0; i < dataList.length; i++) {
							var roleObj = roleList.find(function(x) {
								return x.id == dataList[i].roleId
							})
// 							if (roleObj != undefined) {
// 								dataList[i].roleName = roleObj.name
// 							} else {
// 								dataList[i].roleName = '超级管理员'
// 							}
							var deptObj = deptList2.find(function(x) {
								return x.id == dataList[i].user.deptId
							})
							if (deptObj != undefined) {
								dataList[i].user.deptName = deptObj.fullName
							} else {
								dataList[i].user.deptName = ''
							}
			
						}
						this.dataList = data.data;
						this.totalPage = data.totalCount
						// this.totalPage = data.page.totalCount
					} else {
						this.dataList = []
						this.totalPage = 0
					}
					this.dataListLoading = false
				})
			},
			reSetPwd() {
				if (this.dataListSelections.length == 0) {
					this.$message({
						message: '请选择要重置的账号！',
						type: 'error'
					});
					return
				}
				if (this.dataListSelections.length > 1) {
					this.$message({
						message: '一次只可重置一个账号！',
						type: 'error'
					});
					return
				}
				let obj = {
					userId:this.dataListSelections[0].user.id
				}
				this.$httpAes({
					url: this.$httpAes.adornUrl('/user/resetpswd'),
					method: 'post',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						
						'token': this.$cookie.get('token'),
					},
					data: Qs.stringify(obj)
				}).then(({
					data
				}) => {
					if (data.status) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
						this.getDataList()
					}
				})
			},
					checkSelectable (row, index) {
			    if( row.user.id== JSON.parse(sessionStorage.userInfo).id ){
					return false;
			  }else{
			    return true;
			  }
			},
			// 每页数
			sizeChangeHandle(val) {
				this.pageSize = val
				this.pageIndex = 1
				this.getDataList()
			},
			// 当前页
			currentChangeHandle(val) {
				this.pageIndex = val
				this.getDataList()
			},
			// 多选
			selectionChangeHandle(val) {
				this.dataListSelections = val
			},
			// 新增 / 修改
			addOrUpdateHandle(id) {
				if (this.dataListSelections.length == 0) {
					this.$message({
						message: '请选择要编辑的账号！',
						type: 'error'
					});
					return
				}
				if (this.dataListSelections.length > 1) {
					this.$message({
						message: '一次只可编辑一个账号！',
						type: 'error'
					});
					return
				}
				this.addOrUpdateVisible = true
				this.$nextTick(() => {
					this.$refs.addOrUpdate.init(id)
				})
			},
			//添加账号
			addAccount(id) {
				this.addVisible = true
				this.$nextTick(() => {
					this.$refs.addAccount.init(id)
				})
			},
			// 删除
			deleteHandle(id) {
				if (this.dataListSelections.length == 0) {
					this.$message({
						message: '请选择要删除的账号！',
						type: 'error'
					});
					return
				}
				if (this.dataListSelections.length > 1) {
					this.$message({
						message: '一次只可删除一个账号！',
						type: 'error'
					});
					return
				}
				let roleArr = ['d651b9e7858b48efa93caaaac48c5205','e89325119fa411e89e43525400557295','e89325119fa411e89e43525400557297','e89325119fa411e89e43525400557296','e89325119fa411e89e43525400557298']
				let roleArr2  = this.dataListSelections[0].listRole
				for(let i=0;i<roleArr2.length;i++){
					for(let j=0;j<roleArr.length;j++){
						if(roleArr2[i]==roleArr[j]){
							roleArr2.splice(i,i+1)
							i--
						}
					}
					
				}
				var obj = {
					'userId':this.dataListSelections[0].user.id,
					roleId: roleArr2.join(',')
				}
				console.log(obj)
				this.$confirm(`确定进行删除操作?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {

					this.$httpAes({
						url: this.$httpAes.adornUrl('user/deleteRole'),
						method: 'post',
						headers: {
							'Content-Type': 'application/x-www-form-urlencoded',
							
							'token': this.$cookie.get('token'),
						},
						data: Qs.stringify(obj)
					}).then(({
						data
					}) => {
						if (data.status) {
							this.$message({
								message: '操作成功',
								type: 'success',
								duration: 1500,
								onClose: () => {
									this.dialogVisible = false
									this.getDataList()
								}
							})
						} else {
							this.$message.error(data.msg)
							this.getDataList()
						}
					})
				}).catch(() => {})
			}
		}
	}
</script>
<style lang="scss">
	.all-rule-list {
		.search-top {
			padding-bottom: 20px;
			overflow: hidden;

			.search-btn {
				font-size: 20px;
				cursor: pointer;
			}
		}
		// .el-dialog{
		// 	width: 600px;
		// }

	}
</style>